import React, { useEffect, useState } from "react";
import { loginState } from '../firebase/auth';
import Login from "./Login";
import Account from "./Account";
import styles from './main.module.css'


const Main = () => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        loginState(setLoggedIn);
    }, []);



    return (
        <main className={styles.mainContent}>
            {
               loggedIn ?
                < Account />
                :
                <Login />
                
            }

        </main>
    );
}
export default Main;