import {auth} from './firebaseConfig'
import {signInWithEmailAndPassword, signOut ,onAuthStateChanged, setPersistence, browserSessionPersistence} from "firebase/auth";



const getData = (email,password)=>{
    //signInWithEmailAndPassword(auth, email, password)
    setPersistence(auth, browserSessionPersistence)
    .then(()=>{
        return signInWithEmailAndPassword(auth, email, password);
    })
    .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode,errorMessage);
    });

}

const logout = () => {
    signOut(auth).then(() => {
        console.log("User signed out successfully!");
    }).catch((error) => {
        console.error("Error signing out: ", error);
    });
};

const loginState = (callback)=>{
    onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      callback(true);
      // ...
    } else {
        callback(false);
      // User is signed out
      // ...
    }
    });
}

export {getData, logout,loginState};