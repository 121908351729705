import React from "react";
import styles from './footer.module.css'

const Footer = () => {
    return (
        <footer className={styles.contentMain}>
            <div>
                <p>todos los derechos reservadors 2024</p>
            </div>
            <div className={styles.contact}>
                <p>Contacto: 33 2715 0140</p>
                <p>Correo: correo@contacto.com</p>
            </div>
        </footer>
    );
}

export default Footer;