import React, { useRef, useState } from "react";
import { getData} from "../firebase/auth";

import styles from "./login.module.css"

const Login = () => {
    const [data,setData] = useState(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    

    const onSubmit = (e)=>{
        e.preventDefault();
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        getData(email,password);
        
    }

    return (
        <section className={styles.mainContent}>
            <form action="" className="styles.form" onSubmit={onSubmit}>
                <label className={styles.label}>Nombre</label>
                <input type="email" placeholder="Correo" ref={emailRef} className={styles.input} />
                <label className={styles.label}>Contraseña</label>
                <input type="password" placeholder="Contraseña" ref={passwordRef} className={styles.input} />
                <button type="submit" className={styles.btn} >Ingresar</button>
            </form>
        </section>
    );
}

export default Login;