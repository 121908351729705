import { Document, Text, Page, StyleSheet, Image, View } from "@react-pdf/renderer";
import Logo from "./assets/logo.jpeg";

const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    header: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        width: 500,
    },
    folio: {
        width: 500,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: ".5cm",
        alignItems: 'flex-end'
    },
    fecha: {
        fontSize: '12pt'
    },
    number: {
        fontSize: '15pt',
        color: '#DC143C',
        marginLeft: '.5cm',
        marginBottom: '1.5',
        textAlign: 'left'
    },
    description: {
        display: 'flex',
        width: 350,
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    text: {
        display: 'flex',
        width: 350
    },
    title: {
        fontSize: 15,
        textAlign: "center",
        fontWeight: "bold",
    },
    subtitle: {
        fontSize: 10,
        textAlign: "center"
    },
    section: {
        display: "flex",
        flexDirection: "row",
        margin: 10,
        padding: 10,
    },
    parragraph: {
        fontSize: '15pt',
        textAlign: "justify",
        lineHeight: 1,
        margin: 10,
    },
    underline: {
        textDecoration: 'underline',
    },
    box: {
        borderStyle: "solid",
        borderWidth: 3,
        padding: '.5cm',
        borderColor: 'black',
        borderRadius: 5,
        marginBottom: '2cm'
    },
    logo: {
        width: 100,
        height: 'auto'
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
});

function Recibo({ data }) {
    const folio = String(data.folio);
    const formatDate = (timestamp) => {
        if (!timestamp) return "Fecha no disponible";
        const date = timestamp.toDate();
        const day = date.getDate();
        const month = date.toLocaleString('es-ES', { month: 'long' });
        const year = date.getFullYear();
        return ` A ${day} del mes de ${month} del ${year}`;
    };
    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.box}>
                    <View style={styles.header}>

                        <Image src={Logo} style={styles.logo} />
                        <View style={styles.description}>
                            <Text style={styles.number}>No. {folio.padStart(6, '0')}</Text>
                            <View style={styles.text}>
                                <Text style={styles.title}>
                                    Campaña de Protección Familiar
                                </Text>
                                <Text style={styles.subtitle}>
                                    CON LA CONFIANZA QUE CON UNA LLAMADA LO RESUELVE TODO.
                                </Text>
                                <Text style={styles.subtitle}>
                                    RFC MALW691022R67
                                    Domicilio: industria #1236 col. San Juan Bosco, Guadalajara, Jal. Mex
                                </Text>
                            </View>

                        </View>
                    </View>
                    <View style={styles.info}>
                        <Text style={styles.parragraph}>
                            Fecha: {formatDate(data.date)} {'\n '}
                            Se recibió de: <Text style={styles.underline}>{data.name} {data.lastName}</Text>, el monto de $<Text style={styles.underline}>{data.payment}</Text>
                        </Text>
                        <Text style={styles.parragraph}>
                            Pago a cuenta: <Text style={styles.underline}>{data.id}</Text>              Recibió: <Text style={styles.underline}>{data.received}</Text>
                        </Text>
                        <Text style={styles.parragraph}>
                            Forma de pago: <Text style={styles.underline}>Pago por internet</Text>              Id de pago: <Text style={styles.underline}>{data.payment_id}</Text>
                        </Text>
                    </View>

                </View>

                <View style={styles.box}>
                    <View style={styles.header}>

                        <Image src={Logo} style={styles.logo} />
                        <View style={styles.description}>
                            <Text style={styles.number}>No. {folio.padStart(6, '0')}</Text>
                            <View style={styles.text}>
                                <Text style={styles.title}>
                                    Campaña de Protección Familiar
                                </Text>
                                <Text style={styles.subtitle}>
                                    CON LA CONFIANZA QUE CON UNA LLAMADA LO RESUELVE TODO.
                                </Text>
                                <Text style={styles.subtitle}>
                                    RFC MALW691022R67
                                    Domicilio: industria #1236 col. San Juan Bosco, Guadalajara, Jal. Mex
                                </Text>
                            </View>

                        </View>
                    </View>
                    <View style={styles.info}>
                        <Text style={styles.parragraph}>
                            Fecha: {formatDate(data.date)} {'\n '}
                            Se recibió de: <Text style={styles.underline}>{data.name} {data.lastName}</Text>, el monto de $<Text style={styles.underline}>{data.payment}</Text>
                        </Text>
                        <Text style={styles.parragraph}>
                            Pago a cuenta: <Text style={styles.underline}>{data.id}</Text>              Recibió: <Text style={styles.underline}>{data.received}</Text>
                        </Text>
                        <Text style={styles.parragraph}>
                            Forma de pago: <Text style={styles.underline}>Pago por internet</Text>              Id de pago: <Text style={styles.underline}>{data.payment_id}</Text>
                        </Text>
                    </View>

                </View>

            </Page>
        </Document>
    );
}

export default Recibo;