import React from 'react';
import { Document, Text, Page, StyleSheet, Image, View } from "@react-pdf/renderer";
import Logo from "./assets/logo.jpeg";

// Estilos para el documento PDF
const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        width: 500,
    },
    folio: {
        width: 500,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: ".5cm",
        alignItems: 'flex-end',
    },
    fecha: {
        fontSize: '12pt',
    },
    number: {
        fontSize: '15pt',
        color: '#DC143C',
        marginLeft: '.5cm',
        marginBottom: '1.5',
        textAlign: 'left',
    },
    description: {
        display: 'flex',
        width: 350,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    text: {
        display: 'flex',
        width: 350,
    },
    title: {
        fontSize: 15,
        textAlign: "center",
        fontWeight: "bold",
    },
    subtitle: {
        fontSize: 10,
        textAlign: "center",
    },
    parragraph: {
        fontSize: '15pt',
        textAlign: "justify",
        lineHeight: 1,
        margin: 10,
    },
    underline: {
        textDecoration: 'underline',
    },
    box: {
        borderStyle: "solid",
        borderWidth: 3,
        padding: '.5cm',
        borderColor: 'black',
        borderRadius: 5,
        marginBottom: '2cm',
    },
    logo: {
        width: 100,
        height: 'auto',
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dataItem: {
        marginBottom: 10,
    },
    label: {
        fontWeight: 'bold',
        fontSize: 10
    },
    infoText: {
        marginLeft: 5,
        fontSize: 10
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        minHeight: '.8cm'
    },
    tableCol1: {
        width: "10%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol2: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol5: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 8,
    },
    tableCellBottom: {
        margin: "auto",
        marginTop: 5,
        fontSize: 8,
        borderStyle: "solid",
        borderBottomWidth: 1,
    },
});

function PaymentStatus({ data, user}) {

    const totalPayments = (data)=>{
        let total = 0;
        data.forEach(payment => {
            total += parseFloat(payment.payment) || 0;
        });
        return total;
    }

    const formatDate = (timestamp) => {
        if (!timestamp) return "Fecha no disponible";
        const date = timestamp.toDate();
        const day = date.getDate();
        const month = date.getMonth() + 1; // Los meses empiezan desde 0
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const getTimestamp = () => { 
        const date = new Date(); 
        const day = String(date.getDate()).padStart(2, '0'); 
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        // Los meses empiezan desde 0 
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.box}>
                    <View style={styles.header}>
                        <Image src={Logo} style={styles.logo} />
                        <View style={styles.description}>
                            <Text style={styles.number}>Fecha {getTimestamp()}</Text>
                            <View style={styles.text}>
                                <Text style={styles.title}>Campaña de Protección Familiar</Text>
                                <Text style={styles.subtitle}>CON LA CONFIANZA QUE CON UNA LLAMADA LO RESUELVE TODO.</Text>
                                <Text style={styles.subtitle}>
                                    RFC MALW691022R67 Domicilio: industria #1236 col. San Juan Bosco, Guadalajara, Jal. Mex
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.info}>
                        <Text style={styles.parragraph}>
                            Estado de cuenta
                        </Text>
                    </View>

                    <View style={styles.info}>
                        <Text style={styles.parragraph}>
                            Cuenta: <Text style={styles.underline}>{user.id}</Text>{'  '}
                            Usuario: <Text style={styles.underline}>{user.name}</Text>
                        </Text>
                    </View>

                    <View style={styles.info}>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol1}>
                                    <Text style={styles.tableCell}>Folio:</Text>
                                </View>
                                <View style={styles.tableCol1}>
                                    <Text style={styles.tableCell}>Contrato:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Nombre:</Text>
                                </View>
                                <View style={styles.tableCol1}>
                                    <Text style={styles.tableCell}>Monto:</Text>
                                </View>
                                <View style={styles.tableCol1}>
                                    <Text style={styles.tableCell}>Fecha:</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Recibió:</Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={styles.tableCell}>Identificador:</Text>
                                </View>
                            </View>
                        </View>


                        {data.length > 0 ? (
                            data.map((payment, index) => (
                                <View key={index} style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol1}>
                                            <Text style={styles.tableCell}>{payment?.folio || "Folio no disponible"}</Text>
                                        </View>
                                        <View style={styles.tableCol1}>
                                            <Text style={styles.tableCell}>{payment?.id || "Contrato no disponible"}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{payment?.name || "Nombre no disponible"}{" "}
                                                {payment?.lastName || "Apellido no disponible"}</Text>
                                        </View>
                                        <View style={styles.tableCol1}>
                                            <Text style={styles.tableCell}>${payment?.payment || "Monto no disponible"}</Text>
                                        </View>
                                        <View style={styles.tableCol1}>
                                            <Text style={styles.tableCell}>{payment?.date ? formatDate(payment.date) : "Fecha no disponible"}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{payment?.received || "Recibió no disponible"}</Text>
                                        </View>
                                        <View style={styles.tableCol2}>
                                            <Text style={styles.tableCell}>{payment?.payment_id || "No aplica"}</Text>
                                        </View>
                                    </View>
                                </View>
                                
                            ))
                        ) : (
                            <Text>No hay datos disponibles</Text>
                        )}
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol5}>
                                    <Text style={styles.tableCell}>Total:</Text>
                                </View>
                                <View style={styles.tableCol5}>
                                    <Text style={styles.tableCell}>${totalPayments(data)} MXN</Text>
                                </View>
                               
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default PaymentStatus;
