import React from 'react';
import { pdf } from '@react-pdf/renderer';
import Recibo from './Recibo';
import PaymentStatus from './paymentStatus';

const generateReceipt = async (data) => {
  const blob = await pdf(<Recibo data={data} />).toBlob();
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `recibo_${data.folio}_de_${data.name}.pdf`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

/*const generarContrato = async (data) => {
  const blob = await pdf(<Contrato data={data} />).toBlob();
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `Contrato_${data.id}_de_${data.name}__${data.lastName}.pdf`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url); 
};*/

const generarStatus = async (data, user) => {
  const name = `${user.data.name} ${user.data.lastName}`;
  const id = user.data.id;

  const blob = await pdf(<PaymentStatus data={data} user={{ name, id}} />).toBlob();
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `Estado__cuenta_${id}.pdf`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url); 
};

export {generateReceipt, generarStatus};