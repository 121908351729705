import React, { useState } from "react";
import styles from "./buy.module.css";
import { NavLink } from "react-router-dom";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import axios from "axios";

export const Buy = ({ user }) => {
    const [preferenceId, setPreferenceId] = useState(null);
    initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY, { locale: "es-MX" });

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            const id = await createPreference();
            if (id) {
                setPreferenceId(id);
            }
        } catch (error) {
            window.alert('Error al registrar o guardar el pago:', error);
        }
    };

    const createPreference = async () => {
        try {
            const response = await axios.post("https://api-pgf6bd2qya-uc.a.run.app/create_preference", {
                title: `Pago a Cuenta ${user.id}`,
                quantity: 1,
                price: 330,
            });

            const { id } = response.data;
            return id;
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <section className={styles.mainContent}>
            <form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.data}>
                    <div className={styles.dataItem}>
                        <label className={styles.label}>Folio:</label>
                        <h2 className={styles.info}>{user?.id || "Folio no disponible"}</h2>
                    </div>
                    <div className={styles.dataItem}>
                        <label className={styles.label}>Nombre:</label>
                        <h2 className={styles.info}>{user?.name || "Nombre no disponible"}</h2>
                    </div>
                    <div className={styles.dataItem}>
                        <label className={styles.label}>Apellido:</label>
                        <h2 className={styles.info}>{user?.lastName || "Apellido no disponible"}</h2>
                    </div>
                    <div className={styles.dataItem}>
                        <label className={styles.label}>Monto:</label>
                        <h2 className={styles.info}>$330 MXN</h2>
                    </div>
                </div>
                <div className={styles.formContent}>
                    <div>
                        {preferenceId && <Wallet initialization={{ preferenceId: preferenceId }} customization={{ texts: { valueProp: 'smart_option' } }} />}
                    </div>
                    <div className={styles.btnContent}>
                        <NavLink to="/" className={styles.btn}>Cancelar</NavLink>
                        <button type="submit" className={styles.btn}>Pagar</button>
                    </div>
                </div>
            </form>
        </section>
    );
};
